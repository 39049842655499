import { getFormVersions } from "./form-versions.resource.js";

let versions,
  fetching,
  queue = [];

export function getVersions() {
  return new Promise((resolve, reject) => {
    if (versions && !fetching) {
      resolve(versions);
    } else {
      queue.push({ resolve, reject });

      if (!fetching) {
        fetching = true;

        getFormVersions().subscribe(
          _versions => {
            fetching = false;
            versions = _versions;
            queue.forEach(({ resolve }) => resolve(versions));
            queue = [];
          },

          error => {
            fetching = false;
            queue.forEach(({ reject }) => reject(error));
            queue = [];
          }
        );
      }
    }
  });
}
