import auth from "cp-client-auth!sofe";
import { from } from "rxjs";
import { switchMap, map, first } from "rxjs/operators";
import { catchAsyncStacktrace } from "auto-trace";

import { infoToast } from "toast-service!sofe";

import * as versionRevisionUtils from "./version-revision.utils.js";
import { getObservable } from "./form-versions.resource.js";
import { getVersions } from "./form-versions.service.js";

const overrides = {};

export function updateObservable(type, year = "2018") {
  auth
    .getLoggedInUserAsObservable()
    .pipe(
      first(),
      switchMap((loggedInUser) =>
        from(getVersions()).pipe(
          map((versions) => ({ versions, loggedInUser }))
        )
      )
    )
    .subscribe(({ versions, loggedInUser }) => {
      const isSme = auth.hasAccess(loggedInUser)("sme");
      const active = {
        version: versionRevisionUtils.getVersionByType(versions, type, year),
        revision: null,
      };

      // For sme's, default to draft
      if (isSme) {
        const versionIdToUse = window.localStorage.getItem(
          `sme:${type}-${year}:version-to-use`
        );
        const revisionIdToUse = window.localStorage.getItem(
          `sme:${type}-${year}:revision-to-use`
        );

        if (versionIdToUse) {
          active.version =
            versions?.find((version) => version.id === versionIdToUse) ||
            active.version;
        }

        if (revisionIdToUse && active.version) {
          active.revision = active.version.revisions.find(
            (revision) => revision.id === revisionIdToUse
          );
        }

        if (!active.revision && active.version) {
          // default to draft for sme's
          active.revision = active.version.revisions.find(
            (revision) => revision.status === "workInProgress"
          );
        }

        if (!overrides[`${type}-${year}`]) {
          overrides[`${type}-${year}`] = getObservable(type)
            .asObservable()
            .subscribe((newActive) => {
              window.localStorage.setItem(
                `sme:${type}-${year}:version-to-use`,
                newActive.version.id
              );
              window.localStorage.setItem(
                `sme:${type}-${year}:revision-to-use`,
                newActive.revision.id
              );
            });
        }
      }

      // use the live revision if not sme or no unpublished revision
      if (!active.version || !active.revision) {
        useLiveRevision(active, versions);
      }

      // check that everything is there to be bootstrapped
      if (!active.version || !active.revision) {
        if (isSme) {
          smeHasFailed();
        } else {
          throw new Error(
            `Cannot bootstrap forms tab in resolution cases menu -- no revision has been specified`
          );
        }
      } else {
        getObservable(type).next(active);
      }
    }, catchAsyncStacktrace());
}

function useLiveRevision(active, versions) {
  try {
    active.revision = versionRevisionUtils.getLiveRevision(active.version);
  } catch (ex) {
    active.version = null;
    active.revision = null;
  }
}

function smeHasFailed() {
  infoToast(
    `The SME tool has neither a published nor an unpublished revision`,
    `My bad`,
    null,
    8000
  );
}
