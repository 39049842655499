import React from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";

import { setActiveRevision } from "./toggle-modes.js";

export default class RevisionSelector extends React.PureComponent {
  render() {
    const { revisions, activeRevision, engagementType } = this.props;

    return (
      <div className="cp-flex-column">
        <strong className="cp-mr-16 cp-mb-4">
          {engagementType === "taxRes" ? "Tax resolution revision" : "Revision"}
        </strong>
        <CpSelectSingle
          data={revisions}
          onChange={this.updateRevision}
          placeholder="Select a revision"
          value={activeRevision}
          contentWidth="md"
        />
      </div>
    );
  }

  updateRevision = (revision) => {
    setActiveRevision(revision.id);
  };
}
