import { findKey } from "lodash";

export const revisionStatuses = {
  ALIVE: "live",
  CANDIDATE_HOT_FIX: "releaseCandidateHotFix",
  CANDIDATE_STANDARD: "releaseCandidateStandard",
  PUBLISHED: "published",
  WORK_IN_PROGRESS: "workInProgress",
};

export function getVersionWithLiveRevision(versions) {
  const liveVersions = versions.filter(versionsWithLiveRevision);

  return liveVersions.length > 0 ? liveVersions[0] : null;
}

export function getLiveRevision(version) {
  const liveRevs = ((version && version.revisions) || []).filter(isLive);

  return liveRevs.length > 0 ? liveRevs[0] : null;
}

export function getDraftRevision(version) {
  return version.revisions.find(
    (revision) => revision.status == revisionStatuses.WORK_IN_PROGRESS
  );
}

export function getVersionByType(versions, type, year) {
  return versions?.find(
    (version) =>
      version.product === type && (version.year ? version.year === year : true)
  );
}

export function getRelevantVersions(versions, type) {
  const relevantVersions =
    type && versions
      ? versions.filter((version) => {
          return version.product ? version.product === type : true;
        })
      : versions;
  return relevantVersions;
}

export function getNewestUnpublishedRevision(version) {
  const sortedUnpublishedRevisions = ((version && version.revisions) || [])
    .filter(isUnpublished)
    .sort(byDescendingCreationDate);

  return sortedUnpublishedRevisions.length > 0
    ? sortedUnpublishedRevisions[0]
    : null;
}

export function versionsWithLiveRevision(version) {
  return version.revisions.filter(isLive).length > 0;
}

export function isUnpublished(revision) {
  return revision.isPublished !== true;
}

export function byAscendingCreationDate(revision1, revision2) {
  return revision1.creationDate < revision2.creationDate ? -1 : 1;
}

export function byDescendingCreationDate(revision1, revision2) {
  return revision1.creationDate > revision2.creationDate ? -1 : 1;
}

export function ensureLiveRevision(versions) {
  const versionWithLiveRevision = getVersionWithLiveRevision(versions);
  if (!versionWithLiveRevision) {
    throw new Error(`There is no live revision in the SME tool`);
  }
}

export const isProductionRevision = (revision) =>
  revision.isPublished ||
  [revisionStatuses.PUBLISHED, revisionStatuses.ALIVE].includes(
    revision.status
  );

export const isLive = (revision) =>
  revision.status === revisionStatuses.ALIVE || revision.isLive;

export const isReleaseCandidate = (revision) =>
  [
    revisionStatuses.CANDIDATE_HOT_FIX,
    revisionStatuses.CANDIDATE_STANDARD,
  ].includes(revision.status);

export const isNormalReleaseCandidate = (revision) =>
  revision.status === revisionStatuses.CANDIDATE_STANDARD;

export const isHotFix = (revision) =>
  revision.status === revisionStatuses.CANDIDATE_HOT_FIX;

export const isPublished = (revision) =>
  revision.status === revisionStatuses.PUBLISHED || revision.isPublished;

export const isDraft = (revision) =>
  !isLive(revision) &&
  !isPublished(revision) &&
  (revision.status === revisionStatuses.WORK_IN_PROGRESS || !revision.status);

export const getRevisionLabel = (revision) => {
  if (isLive(revision)) {
    return "Prod/Live";
  } else if (isHotFix(revision)) {
    return "RC (hotfix)";
  } else if (isNormalReleaseCandidate(revision)) {
    return "RC";
  } else if (isDraft(revision)) {
    return "Draft";
  } else {
    return "Published";
  }
};

const getStatusKey = (revision) => {
  findKey(revisionStatuses, (status) => status === revision.status);
};

export const sortRevisions = (revisions) => {
  return revisions.sort((revA, revB) => {
    const keyA = getStatusKey(revA);
    const keyB = getStatusKey(revB);

    if (keyA < keyB) {
      return -1;
    }

    if (keyA > keyB) {
      return 1;
    }

    return 0;
  });
};
