import React from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";

import { useNewVersion } from "./toggle-modes.js";

export default class VersionSelector extends React.PureComponent {
  render() {
    const { activeVersion, versions } = this.props;

    if (!versions || !activeVersion) {
      return null;
    }

    return (
      <div className="cp-mb-8 cp-flex-column">
        <strong className="cp-mr-16 cp-mb-4">Tax prep version</strong>
        <CpSelectSingle
          data={versions}
          onChange={this.updateVersion}
          placeholder="Select a version"
          value={activeVersion}
          contentWidth="md"
        />
      </div>
    );
  }

  updateVersion = (version) => {
    useNewVersion({version, isSme: true});
  };
}
