import { infoToast } from "toast-service!sofe";
import { first, map } from 'rxjs/operators'
import {
  getNewestUnpublishedRevision,
  getLiveRevision,
} from "./version-revision.utils.js";
import {
  getObservable,
} from "./form-versions.resource.js";
import { updateObservable } from './observable-setup.helper.js';

export function usePublishedRevision() {
  return useNewRevision(true);
}

export function useUnpublishedRevision() {
  return useNewRevision(false);
}

export function useNewRevision(shouldNowBeLiveRevision, type = 'taxRes', year) {
  updateObservable(type, year);

  return getObservable(type).asObservable()
    .pipe(
      first(),
      map(active => {
        const newActiveRevision = shouldNowBeLiveRevision
          ? getLiveRevision(active.version)
          : getNewestUnpublishedRevision(active.version);

        if (!shouldNowBeLiveRevision && !newActiveRevision) {
          infoToast(
            `There is no unpublished revision in the version '${active.version.name}'`,
            `I apologize for trying`,
            null,
            5000
          );
          return;
        }

        if (shouldNowBeLiveRevision && !newActiveRevision) {
          infoToast(
            `There is no published revision of the sme version '${active.version.name}'`
          );
          return;
        }

        const newVersion = {
          version: active.version,
          revision: newActiveRevision,
        };

        getObservable(type).next(newVersion);

        return newVersion;
      })
    )
    .toPromise(Promise);
}

export function useNewVersion({version, type = 'taxRes', year, isSme = false}) {
  updateObservable(type, year);

  return Promise.resolve().then(() => {
    if (!version) {
      infoToast(`Could not switch to version '${version}' of the SME tool`);
    }

    const revision =
      getNewestUnpublishedRevision(version) || getLiveRevision(version);
    if (!revision) {
      infoToast(
        `Could not find a revision in the version '${version.name}' of the SME tool`
      );
      return;
    }

    const newVersion = {
      version,
      revision,
    };

    if (isSme) {
      newVersion.revision = newVersion.version.revisions.find(
        (revision) => revision.status === "workInProgress"
      );
    }

    getObservable(type).next(newVersion)
  });
}

export function setActiveRevision(revisionId, type = 'taxRes', year) {
  updateObservable(type, year);

  return getObservable(type).asObservable()
    .pipe(
      first(),
      map(active => {
        const newVersion = {
          version: active.version,
          revision: active.version.revisions.find(
            revision => revision.id == revisionId
          ),
        };

        getObservable(type).next(newVersion);

        return newVersion;
      })
    )
    .toPromise(Promise);
}
