import React from "react";
import { from } from "rxjs";
import { isEmpty } from "lodash";
import { catchAsyncStacktrace } from "auto-trace";
import Cancelable from "react-disposable-decorator";

import { getVersions } from "./form-versions.service.js";
import { getObservable } from "./form-versions.resource.js";
import { getRelevantVersions } from "./version-revision.utils.js";
import { useNewVersion } from "./toggle-modes.js";
import VersionSelector from "./version-selector.component.js";
import RevisionSelector from "./revision-selector.component.js";

@Cancelable
export default class SMEToggleVersionComponent extends React.Component {
  state = {
    activeVersion: null,
    activeRevision: null,
    versions: null,
    engagementType: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEmpty(prevState.versions) &&
      nextProps.engagementType !== prevState.engagementType
    ) {
      const initialVersion = prevState.versions.filter(
        (version) => version.product === nextProps.engagementType
      );
      useNewVersion({ version: initialVersion[0], isSme: true });
    }
    return { engagementType: nextProps.engagementType };
  }

  componentDidMount() {
    const { engagementType } = this.state;

    this.props.cancelWhenUnmounted(
      getObservable("taxRes")
        .asObservable()
        .subscribe(
          (active) =>
            this.setState({
              activeRevision: active.revision,
              activeVersion: active.version,
            }),
          catchAsyncStacktrace()
        )
    );

    this.props.cancelWhenUnmounted(
      from(getVersions()).subscribe((versions) => {
        if (engagementType) {
          const initialVersion = versions.filter(
            (version) => version.product === engagementType
          );
          useNewVersion({ version: initialVersion[0], isSme: true });
        }
        this.setState({ versions });
      }, catchAsyncStacktrace())
    );
  }

  render() {
    const { activeRevision, activeVersion, engagementType, versions } =
      this.state;

    return (
      <div>
        {engagementType !== "taxRes" && (
          <VersionSelector
            versions={getRelevantVersions(versions, engagementType)}
            activeVersion={activeVersion}
          />
        )}
        {activeVersion && (
          <RevisionSelector
            revisions={activeVersion.revisions}
            activeRevision={activeRevision}
            engagementType={engagementType}
          />
        )}
      </div>
    );
  }
}
