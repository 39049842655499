import { first } from "rxjs/operators";
import { catchAsyncStacktrace } from "auto-trace";

import { TYPES } from "./form-versions.resource.js";
import { updateObservable } from "./observable-setup.helper.js";

// waits for user object to be available before calling updateObservable once
export async function startUserLoginListener() {
  const authModule = await SystemJS.import("cp-client-auth!sofe");
  const { getLoggedInUserAsObservable } = authModule.default;

  getLoggedInUserAsObservable()
    .pipe(first())
    .subscribe((loggedInUser) => {
      const hasTeamMemberAccess = loggedInUser.role === "TeamMember";
      if (hasTeamMemberAccess) {
        TYPES.forEach((type) => updateObservable(type));
      }
    }, catchAsyncStacktrace());
}
