import { fetchAsObservable } from "fetcher!sofe";
import { ReplaySubject } from "rxjs";
import { pluck } from "rxjs/operators";

const versionObservables = {
  taxRes: new ReplaySubject(1),
  taxPrep: new ReplaySubject(1)
};

export function getFormVersions(includePdfVersions = false) {
  return fetchAsObservable(`/form-versions?noPdfVersions=${!includePdfVersions}`).pipe(
    pluck("form-versions")
  );
}

export function getObservable(type) {
  if (!type || !versionObservables)
    throw new Error(`No observable for: ${type}`);
  return versionObservables[type];
}

export const TYPES = Object.keys(versionObservables);
