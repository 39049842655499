import { timer } from "rxjs";
import { debounce } from "rxjs/operators";

import SMEToggleVersionComponent from "./sme-toggle-version.component.js";
import { updateObservable } from "./observable-setup.helper.js";
import { getObservable } from "./form-versions.resource.js";
import { startUserLoginListener } from "./user.helper";

export const taxResVersionAsObservable = getObservable("taxRes");

export { getVersions } from "./form-versions.service.js";

export {
  setActiveRevision,
  usePublishedRevision,
  useUnpublishedRevision,
} from "./toggle-modes.js";

export { SMEToggleVersionComponent };

export * from "./version-revision.utils.js";

export function getAndFetchVersion(type, year) {
  if (!type)
    throw new Error("Must define the SME version of taxRes or taxPrep");
  updateObservable(type, year);

  return getObservable(type)
    .asObservable()
    .pipe(debounce(() => timer(10)));
}

startUserLoginListener();
